body {
  /*background-color: transparent;*/
}

html {
  /*background: url(https://wallpapertag.com/wallpaper/full/0/3/7/848737-best-sunset-desktop-backgrounds-2560x1600.jpg) no-repeat center center fixed;*/
  /*-webkit-background-size: cover;*/
  /*-moz-background-size: cover;*/
  /*-o-background-size: cover;*/
  /*background-size: cover;*/
}

.no-button-img {
  margin: 10px;
}